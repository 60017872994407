















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import BarChart from '@/components/Charts/BarChart.vue'
import DateRangeDropdown from '@/components/DateRangeDropdown/index.vue'
import CustomRangeFields from '@/components/DateRangeDropdown/CustomRangeFields.vue'
import LineChart from './components/LineChart.vue'
import PanelGroup from './components/PanelGroup.vue'
import NewsGroup from './components/NewsGroup.vue'
import PieChart from './components/PieChart.vue'
import RadarChart from './components/RadarChart.vue'
import TodoList from './components/TodoList/index.vue'
import DashTable from './components/Table/index.vue'
import {
  compareVisitsToAssignments,
  getGroupedAssignations,
  getGroupedClients,
  getGroupedVisits,
  getPropertiesViews
} from '@/api/statistics'
import {
  dateRange,
  getTimeRangeFromString,
  getTranslations,
  hasOfficeService,
  hasPermission,
  hasRoles,
  parseTime
} from '@/utils'
import { TimeRange } from '@/models/TimeRange'
import { getUser } from '@/api/users'
import SpitogatosStatistics from '@/views/dashboard/components/SpitogatosStatistics.vue'
import { getOfficesLight } from '@/api/helpers'

@Component({
  name: 'Dashboard',
  components: {
    SpitogatosStatistics,
    DateRangeDropdown,
    CustomRangeFields,
    BarChart,
    LineChart,
    PanelGroup,
    NewsGroup,
    PieChart,
    RadarChart,
    TodoList,
    DashTable
  }
})
export default class extends Vue {
  private lineChartData: {
    assignments: any[]
    visits: any[]
    searches: any[]
  } = {
    assignments: [],
    visits: [],
    searches: []
  }

  private dropdownValue = ''
  private user = null
  private officeId: any = null
  private offices: any = []
  private pieChartVisitData: any = []
  private pieChartClientData: any = []
  private pieChartViewsData: any = []
  private pieChartData = []
  private range = new TimeRange()
  private tables = [
    { title: 'dashboard.officeNewPropertiesTable', type: 'new_properties' },
    { title: 'dashboard.officeNewApprovalsTable', type: 'approved' },
    { title: 'dashboard.officeSentForApprovalTable', type: 'sent_for_approval' }
  ]

  private hasOfficeService = hasOfficeService
  private getTranslations = getTranslations

  @Watch('requestParams', { deep: true })
  private onRangeChange() {
    this.fetchResults()
  }

  get title() {
    return this.$route.params.id
      ? `${this.$t('dashboard.userStatistics')} (${getTranslations(this.user)})`
      : this.$t('dashboard.title')
  }

  get name() {
    return UserModule.name
  }

  get roles() {
    return UserModule.roles
  }

  get canSeeOfficeList() {
    return this.isSuperAdmin || hasPermission(['VIEW_OTHER_DASHBOARD_STATISTICS'])
  }

  get isSuperAdmin() {
    return hasRoles(['super_admin'], UserModule.roles)
  }

  get isCustomStatistics() {
    return this.isUserStatistics || this.isOfficeStatistics
  }

  get isUserStatistics() {
    return !!this.$route.params.id
  }

  get isOfficeStatistics() {
    return !!this.officeId
  }

  get requestParams() {
    return {
      range: this.range,
      officeId: this.officeId,
      userId: this.$route.params.id || null
    }
  }

  get dateRangeValues() {
    return dateRange(
      parseTime(new Date(this.range.from || 0), '{y}-{m}-{d}'),
      parseTime(new Date(this.range.to || 0), '{y}-{m}-{d}')
    )
  }

  private addDateToArray(baseArr: any, targetArr: any, date: any) {
    const propertyIdx = baseArr.findIndex((item: any) => {
      const displayMonth = item.month < 10 ? '0' + item.month : item.month
      return item.year + '-' + displayMonth + '-01' === date
    })
    if (propertyIdx !== -1) {
      const property = baseArr[propertyIdx]
      targetArr.push({
        value: property.count,
        header: date.substring(0, date.length - 3)
      })
    } else {
      targetArr.push({
        value: 0,
        header: date.substring(0, date.length - 3)
      })
    }
  }

  private onRangeUpdate(params: any) {
    this.dropdownValue = params.dropdownValue
    this.range = params.range
  }

  private fetchResults() {
    compareVisitsToAssignments(this.requestParams).then(({ data }) => {
      this.lineChartData.assignments = []
      this.lineChartData.visits = []
      this.lineChartData.searches = []
      this.dateRangeValues.forEach((date) => {
        this.addDateToArray(data.properties, this.lineChartData.assignments, date)
        this.addDateToArray(data.visits, this.lineChartData.visits, date)
        this.addDateToArray(data.searches, this.lineChartData.searches, date)
      })
    })
    getGroupedAssignations(this.requestParams).then(({ data }) => {
      this.pieChartData = data.map((item: any) => {
        return {
          name: this.$t('dashboard.' + item.available_for + '_' + item.assignation_type),
          value: item.count
        }
      })
    })
    getGroupedVisits(this.requestParams).then(({ data }) => {
      this.pieChartVisitData = Object.keys(data).map((key: string) => {
        return {
          name: this.$t('dashboard.' + key),
          value: data[key]
        }
      })
    })
    getGroupedClients(this.requestParams).then(({ data }) => {
      this.pieChartClientData = data.map((item: any) => {
        return {
          name: this.$t('found_from.' + item.foundFrom),
          value: item.value
        }
      })
    })
    if (!this.isUserStatistics) {
      getPropertiesViews(this.requestParams).then(({ data }) => {
        this.pieChartViewsData = data.map((item: any) => {
          return {
            name: item.full_code,
            value: item.views
          }
        })
      })
    }
  }

  created() {
    this.dropdownValue = 'last_year'
    this.range = getTimeRangeFromString('last_year')
    if (this.$route.params.id) {
      getUser({
        id: this.$route.params.id
      }).then(({ data }) => {
        this.user = data
      })
    }
    if (this.canSeeOfficeList) {
      getOfficesLight({
        query: {
          pagination: {
            fetch_all: true
          }
        }
      }).then(({ data }) => {
        this.offices = data.collection
        if (!this.isSuperAdmin) {
          this.officeId = UserModule.officeId
        }
      })
    }
  }
}
