




































import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import {
  getPanelGroupCounts
} from '@/api/statistics'
import { hasPermission, hasRoles } from '@/utils'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'PanelGroup'
})

export default class extends Vue {
  @Prop({ required: true }) private range!: any
  @Prop({ required: false }) private userId!: any
  @Prop({ required: false }) private officeId!: any

  private chartData = {}
  private groups = [{
    class: 'icon-people',
    icon: 'el-icon-user',
    label: 'collaborators',
    key: 'usersCount',
    disabled: !this.showPanel(['VIEW_USERS']),
    callback: () => {
      return this.$router.push({
        path: '/user/list',
        query: {
          active: 'true'
        }
      })
    }
  }, {
    class: 'icon-message',
    icon: 'el-icon-message',
    label: 'properties',
    key: 'propertiesCount',
    disabled: !this.showPanel(['VIEW_PROPERTIES']),
    callback: () => {
      return this.$router.push({
        path: '/property/list',
        query: {
          status: 'approved',
          assignation_state: [
            'active',
            'under_negotiation',
            'under_offer_status'
          ]
        }
      })
    }
  }, {
    class: 'icon-money',
    icon: 'el-icon-money',
    label: 'searches',
    key: 'searchesCount',
    disabled: !this.showPanel(['VIEW_SEARCHES']),
    callback: () => {
      return this.$router.push({
        path: '/search/list',
        query: {
          active: 'true'
        }
      })
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-shopping-cart-full',
    label: 'clients',
    key: 'clientsCount',
    disabled: !this.showPanel(['VIEW_CLIENTS']),
    callback: () => {
      return this.$router.push({
        path: '/client/list',
        query: {
          ...this.range
        }
      })
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-call',
    label: 'propertiesForApproval',
    key: 'propertiesForApprovalCount',
    disabled: !this.showPanel(['APPROVE_PROPERTIES', 'LIST_OTHERS_PROPERTIES']),
    callback: () => {
      return this.$router.push({
        path: '/property/list',
        query: {
          status: 'sent_for_approval'
        }
      })
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-ext-call',
    label: 'incomingCalls',
    key: 'callsCount',
    disabled: !this.showPanel(['VIEW_CALLS']),
    callback: () => {
      return this.$router.push({
        path: '/call/list',
        query: {
          ...this.range
        }
      })
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-shopping-cart-full-properties',
    label: 'searches_with_new_properties_count',
    key: 'searchesWithNewPropertiesCount',
    disabled: !this.showPanel(['VIEW_SEARCHES']),
    callback: () => {
      return this.$router.push({
        path: '/search/list',
        query: {
          only_new_properties: 'true'
        }
      })
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-partnership',
    label: 'collaborations',
    key: 'collaborationsCount',
    disabled: !this.showPanel(['VIEW_COLLABORATIONS']),
    callback: () => {
      console.log('nowhere to go')
    }
  }, {
    class: 'icon-shopping',
    icon: 'el-icon-recommendations',
    label: 'recommendations',
    key: 'recommendationsCount',
    disabled: !this.showPanel(['VIEW_RECOMMENDATIONS']),
    callback: () => {
      console.log('nowhere to go')
    }
  }]

  @Watch('range', { deep: true })
  private onRangeChange() {
    this.fetchResults()
  }

  @Watch('officeId')
  private onOfficeIdChange() {
    this.fetchResults()
  }

  private async fetchResults() {
    const { data } = await getPanelGroupCounts({
      range: this.range,
      userId: this.userId || null,
      officeId: this.officeId || null
    })
    this.chartData = data
  }

  private showPanel(privileges: any) {
    return hasRoles(['super_admin'], UserModule.roles) || hasPermission(privileges)
  }

  created() {
    this.fetchResults()
  }
}
